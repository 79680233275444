.smart-skilled {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    position: relative;

    & h2 {
        align-self: flex-start;
        margin: 3rem 0;

        @media screen and (max-width: 800px){
            text-align: center;
        }
    }

    &__article {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        @media screen and (max-width: 800px){
            flex-direction: column;
        }

        &--figure {
            width: 40%;
        }

        &--description {
            width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            & h3 {
                color: var(--mbh-green)
            }

            & p {
                margin-bottom: 50px;
                font-size: 1.2rem;
            }

            @media screen and (max-width: 800px){
                width: 80%;

                & h3 {
                    font-size: 2rem;
                }
            }
        }
    }
}