.clients {
    margin: 6rem auto;

    &__body {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: 90px;

        @media screen and (max-width: 600px) {
            margin-top: 50px;
        }
    }

    &__fig {
        width: 15%;

        @media screen and (max-width: 600px) {
            width: 25%;
        }

        &--7 {
            justify-self: center;
        }
    }
}