.events-edit{
    
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__btns{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__form{
        margin-top: 3rem;
    }

    &__add-attendees {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
    }

    .MuiInput-underline::before {
        left: 0;
        right: 0;
        bottom: 0;
        content: none;
        position: none;
        transition: none;
        border-bottom: none;
        pointer-events: none;
    }
}
