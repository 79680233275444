.floatRight {
    float: right;
    margin-right: 0;
    margin-bottom: 0;
}

.floatLeft {
    float: left;
    margin-top: 0;
    margin-left: 0;
}

.hyperlink {
    color: var(--mbh-green);
    text-decoration: underline;
    cursor: pointer;
}

.cursor {
    cursor: pointer;
}

.btn-as-anchor {
    background: none;
    border: none;
}