.natFileErrors {

    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    & p {
        text-align: center
    }

    &__students {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 50px;

        & li {
            width: 30%;
            margin: 5px;
            list-style-type: circle !important;
        }

        & li:hover {
            color: white;
            cursor: pointer;
            text-decoration: underline;
        }
    }
}