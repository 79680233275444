.copy-table {

    & .cert-table.mce-item-table {
        width: 100% !important
    }

    & .btn {
        margin-bottom: 20px;
    }

    &__html {
        margin-top: 20px;
        margin-bottom: 50px;

         & p {
             margin-bottom: 20px;
         }
    }
}