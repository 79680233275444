@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

:root{
  --mbh-green: #00703c;
  --mbh-red: #98002E;
}

*,
*::before,
*::after{
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html{
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

.notextselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}