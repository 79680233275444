.events-trainers {

    &__dropdown-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__dropdown {
        margin-bottom: 50px;
    }

    &__add {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    @media only screen and (max-width: 600px) {
        &__dropdown {
            margin-bottom: 10px;
        }
    }

}