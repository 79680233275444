.footer{
    padding: 50px 0;
   
    
    &__grid{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        align-items: center;
    }

    &__links{
        display: flex;
        text-align: left;
        line-height: 1.8rem;
       

        &--1, &--2, &--3{
            display: flex;
            flex-direction: column;
            
        }

        &--2, &--3{
            align-self: start;
        }

        @media screen and (max-width: 600px){
            text-align: center;
            grid-column: 1 / -1;
        }
    }

    &__link{
        color: white;
    }

    &__link:hover{
        cursor: pointer;
        opacity: .8;
     }

   

    &__social{
       
        @media screen and (max-width: 1230px){
            grid-column: 1 / -1;
            padding-top: 40px;
        }
    }

    &__favicon{
        color: white;
        outline: none;
        font-size: 2rem;
    }
    
    &__favicon:hover{
        cursor: pointer;
        opacity: .8;
    }

    &__favicon:not(last-item){
       margin-right: 20px;
    }

    &__copyright{
        padding-top: 20px;
        font-size: 0.8rem;

        & p{
            margin-top: 10px;
        }
    }
}