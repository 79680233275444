.training-plan-nav {

    width: 100%;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    &__tab {
        width: 40%;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-bottom: 2px solid #eee;
        padding: 30px;
        margin: 10px;
        color: #cecece;
        cursor: pointer;
        transition: all .1s ease-in-out;
    }

    @media only screen and (max-width: 650px) {
        &__tab {
            width: 80%;
        }
    }

    &__tab:hover, &__tab:active {
        color: var(--mbh-green);
        border-bottom: 2px solid var(--mbh-green);
    }

    &__active-tab {
        color: var(--mbh-green);
        border-bottom: 2px solid var(--mbh-green);
    }
}