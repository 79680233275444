.student-qualifications{
    margin: 6rem 0;
    
    & h5{
        margin: 2rem 0;
    }

    & table {
        width: 100%;
        min-width: 1050px;
    }

    &__completed{
        margin: 3rem 0;

        & h6{
            font-weight: bold;
            margin-bottom: 1rem;
        }
    }

    &__all{
        margin: 6rem 0 3rem 0;
    }

    &__add-qual{
        margin: 4rem 0;
    }

    &__description{
        margin: 3rem 0;

        & h6{
            margin-bottom: 1rem;
        }
    }

    &__border-header{
        border: 1px solid #ccc;
        padding: 1rem;
    }

    &__border-row{
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        padding: 0 1rem;
    }

    &__qualification{
        display: grid;
       
        grid-column-gap: 10px;
        align-items: center;
        min-width: 1050px;
        width: 100%;
        border-bottom: 1px solid #ccc;

        //DONT change below to padding: 1rem 0
        padding-top: 1rem;
        padding-bottom: 1rem;

        &--qualification{
            grid-template-columns: 100px minmax(200px, 1fr) 120px 120px 110px 110px 110px 120px 60px 50px;
        }

        &--funding{
            grid-template-columns: 100px minmax(150px, 1fr) 170px 100px 250px 180px;
        }

        &--fees{
            grid-template-columns: 100px minmax(200px, 1fr) 150px 150px 150px 150px;
        }

        &--fee-item{
            justify-self: end;
        }
        &--delivery{
            grid-template-columns: 100px minmax(200px, 1fr) 400px;
        }
       
    }

    &__competency-table{
        border-bottom: 1px solid #ccc;
        min-width: 1050px;
        width: 100%;
    }

    &__competency{
        display: grid;
        grid-template-columns: 150px minmax(250px, 1fr) 150px 150px 250px;
        grid-column-gap: 10px;
        align-items: center;
        min-width: 1050px;
        width: 100%;
       
        & .table-column {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }

    &__competency:nth-child(even){
        background-color: #cce2d8;
    }

    &__checkbox{
        margin-left: 20px;
    }

    &__deletebutton {
        margin-left: 10px;

        &.hide {
            visibility: hidden;
        }
    }

    &__pending{
        border: 1px solid #ccc;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 1050px;

        @media screen and (max-width: 900px) {
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
        }

        &--button-container{
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        &--manual-quote{
            display: flex;
            justify-content: flex-end;
            align-items: center;

            & div{
                width: 150px;
                margin-right: 20px;
            }
        }

        &--btn-group{
            white-space: nowrap;
        }
        &--btn{
            margin-top: 20px;
            margin-left: 20px;
        }
    }

}

#regQualMessage{
    color: var(--mbh-green);
    font-size: large;
}