.drag-drop{
    &__section{
        margin: 2rem 0 4rem 0;
        & h5{
            margin-bottom: 2rem;
        }
    }

    &__results{
        width: 100%;
        overflow-x: auto;
        padding-bottom: 5px;
        scrollbar-color:#00703c #aaaaaa;
        scrollbar-width: thin;
       
    }

    &__results::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.3);
      }
      
      &__results::-webkit-scrollbar {
        height: 10px;
      }
      
      &__results::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: rgb(0, 112, 60)
      }
    
    // &__results li:nth-child(even){
    //     background-color: #cce2d8;
    // }

    &__result{
        display: grid;
        grid-template-columns: 150px minmax(255px, 1fr) 100px 150px 150px 200px 25px;
        grid-column-gap: 10px;
        align-items: center;
        min-width: 1050px;
        width: 100%;

        @media screen and (max-width:600px){
            grid-template-columns: 150px minmax(255px, 1fr) 100px 150px 150px 200px 25px;
        }

        &--filter {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
        }
    }

    &__result:nth-child(even){
        background-color: #cce2d8;
    }
   
    &__draggable{
       
        cursor: pointer;
    }

    &__draggable-icon{
        @media screen and (max-width:600px){
            display: none;
        }
    }

    &__drop-area{
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #efefef;
    }

    &__plan-acceptance {
        // box-shadow: 1px 4px 10px #ccc;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 auto;
        margin-bottom: 20px;
        border-radius: 20px;

        &--info {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 10px;
            width: 40%;
            min-width: 250px;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 1px 4px 10px #ccc;
        }

        & p {
            margin: 10px;
            text-align: center;
        }
    }

    @media only screen and (max-width: 600px) {
        &__plan-acceptance {
            width: 90%;
        }
    }
}

#div1{
    width: 100%;
    border: 1px solid #dddddd;
}

*[draggable=true] {
    -moz-user-select:none;
    -khtml-user-drag: element;
    -webkit-user-drag: element;
                   -khtml-user-select: none;
                   -webkit-user-select: none;
                   cursor: move;
               }

// input[type="date"]::-webkit-calendar-picker-indicator {
//     background: transparent;
//     bottom: 0;
//     color: transparent;
//     cursor: pointer;
//     height: auto;
//     left: 0;
//     position: absolute;
//     right: 0;
//     top: 0;
//     width: auto;
// }