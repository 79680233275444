.student-events {

    &__container {
        margin-top: 50px;

        &--title {
            margin: 20px 0 20px 0;
        }

        &--past-events {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            & span {
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }

    &__event-cards {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        &--event-details {
            width: 30%;
            min-width: 200px;
            padding: 20px;
            height: 200px;
            margin: 10px;
            box-shadow: 0px 0px 4px #c8c8c8;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            text-align: left;
        }
    }
}