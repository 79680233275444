.diplomaInPM {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & h2 {
        text-align: center;
        width: 80%;
    }

    @media screen and (max-width: 600px) {
        & h2 {
            font-size: 2.2rem;
        }
    }

    &__article {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        height: 70vh;

        @media screen and (max-width: 900px) {
            height: auto;
        }

        @media screen and (max-width: 600px) {
            height: 90vh
        }
    }

    &__figure {
        width: 30%;

        @media screen and (max-width: 900px) {
            width: 40%;
            margin: 50px 0;
        }

        @media screen and (max-width: 600px) {
            width: 70%;
            margin: 50px 0;
        }
    }

    &__description {
        width: 30%;
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 100px;

        @media screen and (max-width: 900px) {
            width: 70%;
            margin: 50px 0;
        }
        
        @media screen and (max-width: 600px) {
            width: 90%;
        }

        & p {
            width: 80%;
            margin: 0 auto;
            margin-bottom: 50px;
        }

    }

    

    &__involve {
        width: 60%;
        margin: 50px 0;

        @media screen and (max-width: 600px) {
            width: 70%;
            margin-top: 150px;
        }

        & h3 {
            text-align: center;
            color: var(--mbh-green);
            margin-bottom: 80px;
            margin-top: 50px;
        }

        & p {
            font-size: 1.2rem;
        }
    }

    &__course-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &--article {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin: 100px 0;

            & article {
                width: 30%;
                text-align: center;

                @media screen and (max-width: 900px) {
                    width: 70%;
                }

                & h4 {
                    width: 80%;
                    margin: 0 auto;

                    @media screen and (max-width: 600px) {
                        width: 100%;
                    }
                }

                & p {
                    margin: 50px 0;
                    font-size: 1.2rem;
                }
            }
        }

        @media screen and (max-width: 900px) {
            &--article {
                flex-direction: column;
            }

            & .column-reverse-flex {
                flex-direction: column-reverse;

            }
        }

        @media screen and (max-width: 600px) {
            & .column-reverse-flex {
                flex-direction: column-reverse;
            }
        }

        &--figure {
            width: 30%;

            @media screen and (max-width: 900px) {
                width: 40%;
                margin-bottom: 50px;
            }

            @media screen and (max-width: 600px) {
                width: 60%;
                margin-bottom: 50px;
            }
        }
    
    }
    
    & hr {
        width: 20%;
        margin-bottom: 50px;
        margin: 0 auto;
    }

    &__btn-group{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        & button {
            margin: 10px;
        }
    }

    @media only screen and (max-width: 400px) {
        &__btn-group {
            margin-bottom: 100px;
        }
    }

    &__smartnskilled{
        display: flex;
        align-items: center;

        & button{
            margin-left: 20px;
        }
    }
    
}