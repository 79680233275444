.navTiles {
    width: 80%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 10px;
    padding-top: 20px;
    margin: 10vh auto;

    @media screen and (max-width: 600px) {
        font-size: 8vw;
    }

    &__icon {
        font-size: 3.5vw;
        height: auto;

        @media screen and (max-width: 600px) {
            font-size: 10vw;
            margin-top: 10px;
        }
    }

    

    &__tile {
        margin: 20px;
        width: 25%;
        height: 15vh;
        border: 2px solid #00703c;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        color: #00703c;
        cursor: pointer;
        transition: all .1s ease-in-out;

        @media screen and (max-width: 1350px) {
            width: 30%;
        }

        @media screen and (max-width: 600px) {
            width: 80%;
        }

        & p {
            width: 100%;
            text-align: center;
            font-size: 1.2vw;

            @media screen and (max-width: 1350px) {
                font-size: 1.5vw;
            }

            @media screen and (max-width: 600px) {
                font-size: 4vw;
            }
        }
    }

    &__tile:hover, &__tile:active {
        background-color: #00703c;
        color: white;
    }

    &__anchor {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: inherit;
    }
    
}