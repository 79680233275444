.privacy{

    &__body{
        @media screen and (min-width: 1300px){
            width: 60%;
        }
    }

    &__heading{
        margin-bottom: 3rem;
        @media screen and (max-width: 600px){
            font-size: 2rem;
        }
    }

    &__subheading{
        margin: 2rem 0;
    }
}