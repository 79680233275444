.student-portal{
    
    display: flex;

    &__nav{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 5;
        display: flex;
        height: 100vh;
        // width: 250px;
        box-shadow: none;
        background: none;
        // transition: all .5s;

        &--small{
            width: 60px;
            // transition: all .2s;
        }

        &--big{
            width: 250px;
            transition: all .2s;
        }

        & ul{
            position: relative;
            display: flex;
            flex-direction: column;
            height: 100vh;
            overflow: hidden;
            box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
            background-color: white;
        }
        

        & li:hover{
            background-color: #cce2d8;
            cursor: pointer;
        }

    }

    &__logo{
        width: 80%;
        padding: 2rem 0 2rem 2rem;
    }

    &__menu-item{
        display: flex;
        align-items: center;
        -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }

    &__menu-icon{
        padding: 0 1rem;
    }

    &__menu-btn{
        position: relative;
        align-self: center;
        transform: translateX(-50%);
    }

    &__content{
        justify-self: center;
        align-self: center;
        padding-left: 4rem;
     

    }
}

