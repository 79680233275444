.edit-quals {

    & h2{
        @media screen and (max-width: 600px){
            font-size: 2.3rem;
        }
    }

    &__all-quals {
        display: flex;
        justify-content: left;
        align-items: center;
        flex-wrap: wrap;
       
        @media screen and (max-width: 600px) {
            overflow-x: scroll;
        }

        & table {
            width: 100%;

            @media screen and (max-width: 600px) {
                width: 1000px;
            }
        }
    }

    &__comp-heading{
        margin: 40px 0 20px 0;
    }

    &__card{
        padding: 15px;
        margin-bottom: 10px;
        border-radius: 10px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        width: 80%;

        @media screen and (max-width: 1000px){
            width: 100%;
        }
    }

    &__delete-qual{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__available-competencies{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

