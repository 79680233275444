.admin-locations{
   &__navigation-buttons{
       position: fixed;
       top: 20px;
       right: 10px;

       display: flex;
       justify-content: center;
       align-items: center;

       & a{
            margin-right: 10px;
            user-select: none;
       }
   }

   &__component{
       padding: 100px 0;
   }
}