.add-qual{

    margin-bottom: 50px;

    .hide {
        display: none;
    }

    & h2 {
       
        @media screen and (max-width: 600px){
            font-size: 2.5rem;
        }
    }

    .modal {
        display: block;
        z-index: 99;
    }

    .modal-fixed-footer {
        width: 40%;
        height: 250px;

        @media screen and (max-width: 600px){
            width: 80%;
        }
    }

    .modal-heading {
        margin-bottom: 50px;
        font-size: 2rem;

        @media screen and (max-width: 600px){
            font-size: 1.5rem;
            margin-bottom: 50px;
        }
    }
}