.codeOfPractice {

    &__heading {
        // width: 60%;
        margin: 2rem auto;
    }

    &__body {
        margin-top: 5rem;
        margin-bottom: 10rem;

        @media screen and (min-width: 1300px){
            // width: 60%;
            margin: 0 auto;
        }

        & h5 {
            margin: 5rem 0 2rem 0;
            color: #00703c;
        }
    }

    &__list {
        margin: 20px 0;
        margin-left: 5%;

        & li {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        & li:not(:last-child) {
            margin-bottom: 15px;
        }
    }
}