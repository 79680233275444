.jobtrainer {
    margin-bottom: 100px;

    & figure {
        margin: 0;
        width: 100%;

        & img {
            width: 100%;
            height: auto;
        }
    }

    &__heading {
        margin: 50px 0;
    }

    & .btn-container {
        margin-top: 30px;
        margin-bottom: 50px;
    }
    
    &__header {
        width: 100%;
        margin: 50px 0;
        
        
    }
    
    &__text-img-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        &--text {
            flex: 2;
            min-width: 300px;
        }

        &--links {
            flex: 2;
            min-width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &__link {
                margin: 5px 0;
            }
        }

        &--img-container {
            flex: 1;
            min-width: 300px;
        }

        @media only screen and (max-width: 705px) {
            &--img-container {
                flex: none;
                width: 60%;
                margin: 0 auto;
            }

            &--links {
                align-items: center;
            }
        }
    }

    & ul {
        margin: 30px 0 30px 50px;

        & li {
            list-style-type: disc;
        }
    }
}