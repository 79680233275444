.download-page{
    margin-top: 3rem;
    &__btn-group{
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__card{
        border-radius: 10px;
        padding: 3rem 6rem;
        margin: 1rem 0;
        width: 100%;
        border: 2px solid var(--mbh-green);
        color: var(--mbh-green);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 20px;      

        & h5{
            padding-bottom: 1rem;
        }

        & p {
            margin-bottom: 20px;
        }
       
    }

    &__select {
        width: 100%;
        padding-bottom: 1rem;

        
    }

    &__btns{
        display: flex;
        justify-content: space-between;
        align-items: center;

        & a{
            margin: 0 10px;
        }
    }

    &__period{
        margin-left: 10px;;
        width: 40px;
    }

    &__upload-msg{
        margin-top: 30px;
    }
}