.step-form{

    min-height: 75vh;

    transition: all .5s;

    &__heading{
        @media screen and (max-width: 600px){
            font-size: 30px;
        }
    }

    &__stepper{
        margin-top: 4rem;
    }

    &__step{
        margin-bottom: 2rem;
        
    }

    &__step-title{
        display: flex;
        align-items: center;
    }   

    &__step-number{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
        cursor: auto;
    }

    &__step-body{
        margin-left: 1.3rem;
        border-left: 1px solid teal;
        animation: moveInTop .5s ease-out;
    }  

    &__step-content{
        margin: 2rem;

        @media screen and (max-width: 600px){
            margin: 1.5rem;
        }
        
    }

    &__step-action{
        margin: 2rem;
        display: flex;
        justify-content: flex-end;
        align-content: center;
       
    }

}

@keyframes moveInTop {
    0% {
      opacity: 0;
      transform: translateY(-3rem);
    }
  
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }

  .btn:hover{
    opacity: .8;
  }

  .btn:active{
      opacity: 1;
  }