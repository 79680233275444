.student-profile{
    margin-top: 6rem;

    &__heading-box{
        display:flex;
        justify-content: space-between;
        align-items: center;
    }

    &__heading{
        display:flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__dropdown-icon{
        opacity: .5;
        cursor: pointer;
    }

    &__btn-save{
        position: fixed;
        right: 120px;
        top: 25%;

        @media screen and (max-width: 600px){
            right: 30px;
            top: 90%;
        }
    }
    
}

