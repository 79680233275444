.value {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    padding: 50px 0;
    background-color: #F2F2F2;
    clip-path: polygon(100% 0%, 95% 50%, 100% 100%, 0% 100%, 5% 50%, 0% 0%);

    @media screen and (max-width: 600px) {
        width: 90%;
    }
    
    &__container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;

        @media screen and (max-width: 600px) {
            flex-direction: column-reverse;
        }
    }

    &__article {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media screen and (max-width: 600px) {
            width: 80%;
        }
    }

    &__p {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        margin: 50px 0;

        @media screen and (max-width: 600px) {
            margin-top: 20px;
        }
    }

    &__fig {
        margin: 0;
        width: 30%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 600px) {
            width: 70%;
            margin: 30px 0;
        }

        &--3 {
            width: 70%;
            margin: 0;
        }
    }
}