.student-training-plan{
    margin: 6rem 0;

    &__section{
        margin: 2rem 0 4rem 0;
        & h5{
            margin-bottom: 2rem;
        }
    }

    &__qual-heading {
        margin: 70px 0;
    }

    &__quote{
        border: 1px solid green;
        border-radius: 10px;
        padding: 4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 900px) {
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
        }

        &--btn-group{
            white-space: nowrap;
        }
        &--btn{
            margin-top: 20px;
            margin-left: 20px;
        }

    }
}

