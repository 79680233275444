.signature{

    padding: 2rem 0;

    &__checkbox-group-title{
        margin: 2rem 0;
    }

    &__annotation{
        font-size: 10px;
        font-style: italic;
    }    

    &__login-label{
        color: #999999;
    }

    &__modal-list > li {
        list-style-type: circle !important;
        margin-left: 40px;
    }
}