.student-portal-home{
    margin-top: 6rem;
    & ul li {
        margin: 1rem 0;
    }

    &__welcome {
        display: flex;
        justify-content: space-around;
        align-items: center;

        @media screen and (max-width: 600px){
            flex-direction: column-reverse;
            text-align: center;
        }

        & figure {
            width: 50%;
            margin: 0;

            @media screen and (max-width: 600px){
                width: 80%;
            }
        }
    }

    &__body{
        margin: 6rem 0;
    }

    &__link{
        color: var(--mbh-green);
        font-weight: bold;
        // text-decoration: underline;
    }

    &__link:hover{
        cursor: pointer;
        filter: brightness(130%);
    }
}