.career-pathways-containing-section {

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    position: relative;

    & #scrollNav {
        position: fixed;
        top: 20%;
        right: 50px;
        text-align: left;
        width: 15%;

        & .stage {
            padding: 10px 20px;
            font-size: 1.15vw;
            cursor: pointer;
            text-align: center;
            border: 2px solid #00703c;
            border-radius: 10px;
            color: #00703c;
            opacity: 0.7;
        }

        & .stage:hover {
            opacity: 1;
        }

        & .stage:not(:last-child) {
            margin-bottom: 20px;
        }

        & .sub-stage {
            border: 2px solid rgba(0,112,60, 0.2);
            padding: 10px;
            width: 90%;
            font-size: 1.1rem;
        }

        & .hide {
            display: none;
        }
    }

    &__item{
        font-size: 12px;

        @media screen and (max-width: 1100px) {
            display: none;
        }
    }


    & .career-pathways {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 60%;

        @media screen and (max-width: 600px) {
            width: 75%;
        }

        & h4 {
            font-size: 1.8rem
        }

        &__btn-container {
            width: 100%;
            margin-top: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            & .btn {
                margin: 10px;
            }
        }

        &__stages {
            margin: 100px 0;
        }

        &__stage-two--headings {
            margin-bottom: 50px;

            & h4 {
                margin: 50px 0;
            }

            &__button {
                margin-top: 50px;
            }

            @media screen and (max-width: 600px) {
                &__br {
                    display: none;
                }
            }
        }

        &__stage-four--headings {

            &__button {
                margin-top: 50px;
            }
        }

        &__stage-four--button {
            margin-bottom: 50px;
        }
    
        &__article {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            width: 80%;
            margin: 100px auto;

            & figure {
                width: 40%;
            }

            & p {
                font-size: 1.2vw;
                width: 40%;
            }

            &--text{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                & p{
                    margin-bottom: 20px;
                    width: 100%;
                }
            }

            @media screen and (max-width: 600px) {
                width: 100%;
                margin: 100px auto;

                & figure {
                    width: 100%;
                    margin: 0 auto;
                    margin-bottom: 50px;
                }

                & p {
                    width: 100%;
                    font-size: 1.4rem;
                }
            }
        }

        &__graduation {
            flex-direction: column;

            // @media screen and (max-width: 600px) {
            //     flex-direction: column-reverse;
            // }
            
            & figure {
                width: 50%;
                margin-top: 100px;

                @media screen and (max-width: 600px) {
                    width: 100%;
                    margin-top: 0;
                }
            }

            & p {
                width: 70%;
                font-size: 1.6vw;

                @media screen and (max-width: 600px) {
                    font-size: 1.5rem;
                    width: 90%
                }
            }

        }

        &__sas-contact-us {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            &--button {
                margin: 10px;
            }
        }

        &__buttons-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 50px;
    
            & button {
                margin: 10px;
            }
        }

    }

    & hr {
        width: 50%;
        margin: 0 auto;
    }


    @media screen and (max-width: 600px) {
        & .reverse-flex {
            flex-direction: column-reverse;
        }
    }
}