.qualifications {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    @media screen and (max-width: 600px) {
        & h2 {
            font-size: 2.2rem;
        }

        & h3 {
            font-size: 1.8rem;
        }
    }

    
    
        & h2 {
            align-self: flex-start;
        }
    
        &__blurb {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            margin: 100px 0;
    
            & figure {
                width: 40%;
            }
    
            & p {
                width: 40%;
                font-size: 1.3rem;
            }
        }
    
        &__figure {
            width: 85%;
            position: relative;

            @media only screen and (max-width: 600px) {
                width: 100%;
            }
    
            &--markers {
                position: absolute;
                z-index: 99;
                width: 14%;
                height: 16%;
                border-radius: 50%;
                cursor: pointer;
                transition: all .2s ease-in-out; 
            }
    
            &--markers:hover, &--markers:active {
                background-color: rgba(255, 255, 255, 0.3)
            }
        }
    
        &__article {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 100px;
    
            &--figure {
                width: 35%
            }
    
            &--description {
                width: 30%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
    
                & p {
                    margin-bottom: 50px;
                    font-size: 1.3rem;
                    text-align: center;
                }
            }
    }

    & input {
        width: 80%;
    }

    &__article {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__boxes {
        margin: 50px 0;
        width: 85%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    &__box {
        width: 30%;
        height: 150px;
        margin: 10px;
        border: 2px solid  #00703c;
        color:  #00703c;
        padding: 20px;
        border-radius: 10px;
        font-size: 1.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .1s ease-in-out;
        cursor: pointer;

        @media screen and (max-width: 1100px) {
            width: 40%;
        }

        @media screen and (max-width: 900px) {
            width: 60%;
        }

        @media screen and (max-width: 750px) {
            width: 80%;
        }

        @media screen and (max-width: 500px) {
            width: 100%;
        }
    }

    &__box:hover, &__box:active {
        background-color: #00703c;
        color: white;
    }
}