.event-details{

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__btns{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        
        & .btn-floating:first-child {
            margin-right: 5px;
        }
    }

    &__body{

        padding-top: 3rem;

        &--icons{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 10px;

            & span{
                margin-left: 10px;
            }
        }
    }
}

