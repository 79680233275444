.qual-comp {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;

    @media screen and (max-width: 600px) {
        & h2 {
            font-size: 6vw;
        }
    }


    &__article {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 80%;
        height: 50vh;

        @media screen and (max-width: 600px) {
            height: 60vh;
        }
    }

    &__option {
        width: 40%;
        height: 20vh;
        font-size: 1.3vw;
        border: 2px solid #00703c;
        color: #00703c;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: 15px;
        transition: all .2s ease-in-out;
        border-radius: 20px;
        cursor: pointer;

        @media screen and (max-width: 600px) {
            width: 80%;
            height: 10vh;
            font-size: 3vw;
            border-radius: 10px;
        }
    }

    &__option:hover, &__option:active {
        transform: scale(1.05, 1.05);
        background-color: #00703c;
        color: white;
    }
}