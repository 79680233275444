.forgot-password {
    margin-bottom: 70px;

    &__card {
        text-align: center;
        width: 50%;
        padding: 20px 20px;
        box-shadow: 0 5px 10px rgba(77, 77, 77, 0.5);

        @media screen and (max-width: 1400px) {
            width: 70%;
        }

        @media screen and (max-width: 1000px) {
            width: 90%;
        }
    }

    &__success{
        margin-top: 6rem;
        padding: 40px;
    }

    &__form{
        width: 80%;
        margin: 0 auto;

        & figure {
            width: 30%;
            height: 70px;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
        }

        & figure img {
            width: 60%;
            height: auto;
        }
    }

    & hr {
        width: 25%;
        margin: 0 auto;
        border: 1px solid #ddd;
        margin-top: 30px;
    }   
}