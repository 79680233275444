.edit-comps {

    & h2{
        @media screen and (max-width: 600px){
            font-size: 2.3rem;
        }
    }

    &__all-comps {
        display: flex;
        justify-content: left;
        align-items: center;
        flex-wrap: wrap;
    }

    &__all-comps {
        // @media screen and (max-width: 1500px) {
        //     overflow-x: scroll;
        // }

        & table {
      
            @media screen and (max-width: 1500px) {
                width: 1000px;
              
            }
        }

        & td:first-child{
            width: 150px;
        }
    }

    // &__comp-dsc {
    //     width: 20%;
    // }

}

.enlarge {
    width: 340px;

    @media screen and (max-width: 600px) {
        width: 250px;
    }
}