.admin-office-locations{

  

    &__delete:hover{
        color: var(--mbh-red);
    }
    
    & td{
        padding-top:0;
        padding-bottom: 0;
    }
}

