.set-password{
    height: 65vh;
    
    &__card {
        text-align: center;
        width: 50%;
        padding: 20px 50px;
        box-shadow: 0 5px 10px rgba(77, 77, 77, 0.5);

        @media screen and (max-width: 1400px) {
            width: 70%;
        }

        @media screen and (max-width: 1000px) {
            width: 90%;
        }
    }


    &__success{
        margin-top: 6rem;
        padding: 6rem;
        height: 40vh;
    }

    &__form{
        width: 80%;
        margin: 0 auto;
    }

    // &__button-container {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    // }
    & hr {
        width: 25%;
        margin: 0 auto;
        border: 1px solid #ddd;
        margin-top: 30px;
    }
   
}