.administer-student{
   // margin: 6rem 0;

    &__card{
        border-radius: 10px;
        padding: 3rem 6rem;
        margin: 3rem 0;
       // width: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 20px;
        transition: all .2s ease-in-out;

        &--green{
            border: 2px solid var(--mbh-green);
            color: var(--mbh-green);
        }

        &--red{
            border: 2px solid var(--mbh-red);
            color: var(--mbh-red);
        }

        @media screen and (max-width: 1350px) {
            //width: 80%
            padding: 3rem;
        }

        @media screen and (max-width: 600px) {
            padding: 3rem;
            //width: 100%
        }
    }

    &__card:hover{
        cursor: pointer;
        color: white;
    }

    &__card--green:hover{
        background-color: var(--mbh-green);
    }

    &__card--red:hover{
        background-color: var(--mbh-red);
    }

    &__card--grey:hover{
        background-color: #cccccc;
    }
    
    &__card-no-hover{
        border-radius: 10px;
        padding: 3rem ;
        margin: 3rem 0;
       // width: 50%;
        
        border-radius: 20px;
        transition: all .2s ease-in-out;

        &--heading {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        &--green{
            border: 2px solid var(--mbh-green);
            color: var(--mbh-green);
        }

        &--red{
            border: 2px solid var(--mbh-red);
            color: var(--mbh-red);
        }

        &--grey{
            border: 2px solid var(#cccccc);
            background-color: #cccccc;
            color: white;
        }

        @media screen and (max-width: 1350px) {
           // width: 80%
           padding: 2rem;
        }

        @media screen and (max-width: 1000px) {
            padding: 2rem;
           margin: 0;

           // width: 100%
        }
    }
}