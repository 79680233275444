.admin-home {
    
    & h2 {
        @media screen and (max-width: 600px){
            font-size: 10vw;
        }
    }
    
    &__all-students {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    &__student {
        width: 20%;
        min-height: 100px;
        border: 2px solid var(--mbh-green);
        color: var(--mbh-green);
        margin: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        transition: all .2s ease-in-out;
        cursor: pointer;

        @media screen and (max-width: 600px) {
            width: 80%;
            min-height: 70px;
            text-align: center;
            padding: 5px;
        }
    }

    &__student:hover, &__student:active {
        background-color: #00703c;
        color: white;
    }

    &__student:hover {
        transform: scale(1.05, 1.05)
    }

    &__student:active {
        transform: scale(0.95, 0.95)
    }
}