.event-create-group {

    &__save-name {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
    }

    &__student-list {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        &--student-card-container {
            width: 100%;
            margin: 10px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap: wrap;
            background-color: white;
            box-shadow: 1px 4px 10px rgb(138, 137, 137);
            border-radius: 30px;
            padding: 20px 10px;

            &__dropdown-container {
                width: 40%;
                margin-left: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                &--dropdown {
                    width: 100%;
                }
            }
        }

        &--student-card { 
            width: 40%;
            border-radius: 30px;   
            background-color: white;
            padding: 20px 40px;
            overflow-x: scroll;
            text-align: center;

            & h6 {
                margin-bottom: 20px;
            }
        }

        // Hiding overflow scroll bars
        &--student-card {
            -ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */
            overflow: visible;
            overflow-x: scroll;
        }
        
        &--student-card::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
        }
    }

    @media only screen and (max-width: 600px) {
        &__student-list {
            &--student-card-container {
                padding: 20px;
                border-radius: 20px;

                &__dropdown-container {
                    width: 100%;
                    margin: 0;
                    margin-top: 20px;
                }
            }

            &--student-card {
                width: 100%;
                padding: 10px;
            }
        }
    }
}