.admin-duplicates{
    & h3 {
        @media screen and (max-width: 600px){
            font-size:2rem;
        }
    }

    &__body{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    &__card{
        width: 45%;
        min-height: 100px;
        border: 2px solid var(--mbh-green);
        color: var(--mbh-green);
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        transition: all .2s ease-in-out;
        cursor: pointer;

        & span{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        @media screen and (max-width: 600px) {
            width: 80%;
            min-height: 70px;
            text-align: center;
            padding: 5px;
        }
    }

    &__card:hover, &__card:active {
        background-color: #00703c;
        color: white;
    }

    &__card:hover {
        transform: scale(1.05, 1.05)
    }

    &__card:active {
        transform: scale(0.95, 0.95)
    }

    &__merge-btn{
        position: fixed;
        top: 200px;
        right: 200px;
    }
}