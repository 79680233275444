.guide {
    padding: 3rem 0;
   
    &__heading{
        margin-bottom: 2rem;
    }

    &__grid{
        display: grid;
        grid-template-columns: repeat(3, minmax(100px, 150px));
        grid-template-rows: repeat(2, 1fr);
        justify-content: center;
        justify-items: center;
        grid-gap: 1rem;
    }

    &__fig {
       margin: 0;
       cursor: pointer;
    }
}