.admin-settings{
   // margin: 6rem 0;
    
    display: grid;

    &__card{
        border-radius: 10px;
        padding: 3rem 6rem;
        margin: 3rem 0;
        width: 50%;
        border: 2px solid var(--mbh-green);
        color: var(--mbh-green);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 20px;
        transition: all .2s ease-in-out;

        @media screen and (max-width: 1350px) {
            width: 80%
        }

        @media screen and (max-width: 600px) {
            padding: 3rem;
            width: 100%
        }
    }

    &__card:last-child {
        border: 2px solid var(--mbh-red);
        color: var(--mbh-red);
    }

    &__card:hover{
        cursor: pointer;
        background-color: #00703c;
        color: white;
    }

    &__card:last-child:hover{
        background-color: var(--mbh-red);
    }
}