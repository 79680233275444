.events-report {

    &__table-container {
        width: 100%;
        overflow-x: auto;
        margin: 30px 0 50px 0;
    }

    &__table {
        min-width: 650px;
        
        & tr {
            border-bottom: 1px solid #ccc;
        }

        & .large-column {
            width: 10%
        }

        &--column-filter {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
        }
    }

    &__filter-btns {
        margin-bottom: 30px;
    }

    & #multi-student-select {
        border-bottom: none;
        margin: 0;
        height: auto;
    }

    & #multi-student-select:focus {
        box-shadow: none;
    }

    &__report-btn {
        margin-right: 5px;
    }

    @media only screen and (max-width: 600px) {
        &__report-btn, &__export-btn {
            margin-top: 20px;
        }
    }
}