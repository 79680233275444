.header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 85vh;
    position: relative;

    &__fig {
        width: 30%;
        height: auto;
        justify-self: flex-start;

        @media screen and (max-width: 600px) {
            width: 60%;
            margin: 0;
        }
    }

    &__text {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 600px) {
            width: 85%;
        }
    }

    &__info {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: justify;
        padding-top: 20px;
        padding-bottom: 50px;
        font-size: 1.2rem;
        width: 100%;

        @media screen and (max-width: 600px) {
            padding: 0;
        }

        &--mobile {
            display: none;

            @media screen and (max-width: 600px) {
                display: block;
                padding-bottom: 20px;
            }
        }

        &--desktop {
            display: block;

            @media screen and (max-width: 600px) {
                display: none;
            }
        }
        
    }

    &__box {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1200px) {
            flex-direction: column;
            margin-top: 0;
        }

        @media screen and (max-width: 900px) {
            margin-top: 100px;
        }

        @media screen and (max-width: 600px) {
            margin-top: 20px;
        }

    }

    &__box h2 {
        margin-top: 0;

        @media screen and (max-width: 900px){
            font-size: 2rem;
            padding: 20px;
        }

        @media screen and (max-width: 900px){
            font-size: 2rem;
            padding-bottom: 0;
        }
    }

    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        
        & button {
            margin: 10px;
        }
    }
}

.arrow {
    position: absolute;
    bottom: 10px;
    border: solid #D0CDE1;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 20px;

    @media screen and (min-width: 600px) and (max-width: 900px) {
        display: none;
    }

    @media screen and (max-width: 600px) {
        display: none;
    }
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

