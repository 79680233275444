.testimonials {

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    &__feedback {
        width: 80%;
        padding: 20px 0;
       
        font-weight: 700;
    }

    &__person {
        padding: 0 20px;
    }

    &__carousel {
        width: 80%;
    }

    &__carousel-container {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    & .carousel-btn {
        width: 50px;
        height: 50px;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;
    }
   
}