.why-mbh {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 800px){
        text-align: center;
    }


    & h2 {
        align-self: flex-start;

        @media screen and (max-width: 800px){
            align-self: center;
        }
    }

    &__blurb {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 100px 0;

        & figure {
            width: 40%;
        }

        & p {
            width: 40%;
            font-size: 1.3rem;
        }

        @media screen and (max-width: 800px){
            flex-direction: column;

            & p {
                width: 80%;
            }
        }
    }

    &__figure {
        width: 85%;
        position: relative;
        margin-top: 50px;
        margin-bottom: 70px;

        &--markers {
            position: absolute;
            z-index: 99;
            width: 14%;
            height: 16%;
            border-radius: 50%;
            cursor: pointer;
            transition: all .2s ease-in-out; 
        }

        &--markers:hover, &--markers:active {
            background-color: rgba(255, 255, 255, 0.3)
        }
    }

    &__article {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 100px;

        &--figure {
            width: 35%
        }

        &--description {
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            & p {
                margin-bottom: 50px;
                font-size: 1.3rem;
                text-align: center;
            }
        }

        @media screen and (max-width: 800px){
            flex-direction: column-reverse;

            &--description {
                width: 80%;
            }
        }
    }
}