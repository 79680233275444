.changePassword {

    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &__card {
        text-align: center;
        width: 50%;
        padding: 20px 50px;
        box-shadow: 0 5px 10px rgba(77, 77, 77, 0.5);

        @media screen and (max-width: 1400px) {
            width: 60%;
        }

        @media screen and (max-width: 850px) {
            width: 90%;
        }
    }

    &__button-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & figure {
            width: 30%;
            height: 70px;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        & figure img {
            width: 60%;
            height: auto;
        }
    }

    &__registerMessage {
        margin-top: 20px;
        border-top: 1px solid #ccc;
        padding-top: 10px;
    }

    & hr {
        width: 25%;
        margin: 0 auto;
        border: 1px solid #ddd;
    }
}