.attendees {
    
    &__box {
        display: flex;
        flex-direction: column;
        

        &--attendees-container {
            display: flex;
            flex-wrap: wrap;
            max-height: 280px;
            overflow-y: scroll;
        }
        
        &--attendee {
            width: 30%; 
            margin: 10px;
            box-shadow: 1px 1px 4px #aaa;
            border-radius: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 20px;
        }

        @media only screen and (max-width: 600px) {
            &--attendee {
                width: 100%;
            }
        }

        &--delete {
            cursor: pointer;
        }
    }

    &__box::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.3);
    }
          
    &__box::-webkit-scrollbar {
        height: 10px;
    }
    
    &__box::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: rgb(0, 112, 60)
    }

    &__box {
        // scrollbar-width: none;
        scrollbar-color: var(--mbh-green) #e4e4e4;
    }

    @media only screen and (max-width: 600px) {
        &--attendee {
            width: 100%;
        }

        &__box::-webkit-scrollbar {
            height: 10px;
            display: none
        }

        &__box {
            scrollbar-width: none;
        }
    }

}