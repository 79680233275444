.contactUs {
    margin-bottom: 70px;

    &__card {
        text-align: center;
        width: 40%;
        padding: 20px 50px;
        box-shadow: 0 5px 10px rgba(77, 77, 77, 0.5);

        @media screen and (max-width: 1400px) {
            width: 60%;
        }

        @media screen and (max-width: 850px) {
            width: 90%;
        }
    }

    &__button-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__registerMessage {
        margin-top: 20px;
        border-top: 1px solid #ccc;
        padding-top: 10px;
    }

    & #tooltip {
        width: 30px;
        height: 30px;
    }

    & #commentSection {
        position: relative;

        & #info-icon {
            position: absolute;
            top: 30%;
            right: -30px;
        }
    }

    & hr {
        width: 25%;
        margin: 0 auto;
        border: 1px solid #ddd;
        margin-top: 30px;
    }

    #spinner {
        width: 20%;
        height: auto;
        margin: 0 auto;

    }
}