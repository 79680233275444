.navBar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 50px;

    &__fig {
        cursor: pointer;
    }

    @media screen and (max-width: 720px) {
        padding-right: 0;
    }

    & nav {
        box-shadow: none;
        background: none;
    }

    & ul {
        display: flex;
        justify-content: flex-end;
        flex-wrap: nowrap;

        @media screen and (max-width: 720px) {
            justify-content: center;
        }

        & li{
            padding-left: 20px;
            white-space: nowrap;
            @media screen and (max-width: 720px) {
                padding-left: 10px;
            }
        }

        & li:hover {
            cursor: pointer;
            color: #00703c;
            filter: brightness(110%);
        }
        
        & li:not(:last-child) {
            margin-right: 30px;
        }
    }

    &__logo{
        width: 60%;

        @media screen and (max-width: 600px) {
            width: 80%;
        }
    }

    &__logo-attendance{
        width: 40%;

        @media screen and (max-width: 600px) {
            width: 80%;
        }
    }
}

#login, #register {
    padding: 10px 15px;
    border-radius: 5px;
}

#slide-out{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
