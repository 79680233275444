.training-plan-info{
    margin: 6rem 0;

    &__section{
        margin: 2rem 0 4rem 0;
        & h5{
            margin-bottom: 2rem;
        }
    }

    &__info-section{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        grid-gap: 20px;
       
    }

   &__info{
    //    height: 180px;
       text-align: center;
       display: flex;
       flex-direction: column;
       justify-content: space-between;
       align-items: center;
       padding: 2rem;
    //    border: 2px solid #00703c;
       border-radius: 15px;
    //    background-color: #cce2d8;
   
   }
   &__info i:hover{
    cursor: pointer;
    transform: scale(1.5);
}

    &__modal{
        padding: 2rem 3rem;

        @media screen and (max-width: 600px){
            padding: 2rem 1rem;
        }
    }
}

