.admin-program-streams{
    width: 50%;

    @media screen and (max-width: 1000px){
        width: 100%;
    }

    &__table{
        width: 100%;
    }

    &__table-column{

        &--id{
            width: 10%;
        }
    }

    &__delete:hover{
        color: var(--mbh-red);
    }

    & td{
        padding-top:0;
        padding-bottom: 0;
    }

}