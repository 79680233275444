.admin-results{
    &__table{
        width: 100%;
        min-width: 1000px;
    }

    &__column-small{
        width: 10%;
    }
    &__column-big{
        width: 40%;
    }

    &__delete:hover{
        color: var(--mbh-red);
    }

    & td{
        padding-top:0;
        padding-bottom: 0;
    }

}