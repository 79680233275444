.viewOtherCourses {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 80px;

    &__div {
        margin-bottom: 50px;

        @media screen and (max-width: 900px) {
            & h4 {
                font-size: 1.8rem;
            }
        }

        @media screen and (max-width: 600px) {
            & h4 {
                font-size: 2rem;
                width: 80%;
                margin: 20px auto;
            }
        }
    }

    &__btns {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        & button {
            margin: 10px;
        }
    }
}