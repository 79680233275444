.search-bar{
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 99;

    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:center;

    &__input{
        margin-top: 2rem;
    }

    &__filters{
        width: 100%;
        display:flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding-right: 20px;
        margin-top: 20px;
       
        @media screen and (max-width: 600px){
            flex-direction: column;
            text-align: left;
            justify-content: flex-start;
        }
    }

    &__switch{
      
        display: flex;
        justify-content: space-between;
        align-self: center;

        @media screen and (max-width: 600px){
            width: 100%;
            justify-content: flex-start;
        }
    }
    
}